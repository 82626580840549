import { logVueErrorHandler } from '@/log/log.ts'
import router from '@/router/index'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Notifications from '@kyvg/vue3-notification'
import * as Sentry from '@sentry/vue'
import { createHead } from '@vueuse/head'
import { createPinia, type PiniaPluginContext } from 'pinia'
import { createApp, markRaw } from 'vue'
import type { Router } from 'vue-router'
import App from './App.vue'
import '@/assets/roboto.css'
import '@/assets/aventa.css'
import '@/assets/index.css'
import '@/assets/notify.css'

function piniaRouterPlugin({store}: PiniaPluginContext) {
    store.router = markRaw(router)
}

async function initialize(): Promise<void> {
    await import("./FontAwesomeLibrary")
    const app = createApp(App)
    app.component("IconFA", FontAwesomeIcon)
    app.use(Notifications)

    const pinia = createPinia()
    pinia.use(piniaRouterPlugin)

    app.use(router)
    app.use(pinia)
    app.use(createHead())

    Sentry.init({
        app,
        enabled: import.meta.env.MODE === 'production',
        dsn: import.meta.env.VITE_SENTRY_DSN,
        release: `csi@${import.meta.env.VITE_APP_VERSION}`,
        integrations: [],
        trackComponents: true,
        tracesSampleRate: 0,
        tracePropagationTargets: [],
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 0.0,
    })
    app.config.errorHandler = logVueErrorHandler
    app.mount("#app")
}

declare module "pinia" {
    interface PiniaCustomProperties {
        router: Router
    }
}

initialize().then(async () => {
    await import('@/ServiceWorker/MonacoEditorWorker')
})






