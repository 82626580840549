import { defineStore } from 'pinia'
import { useRegisterSW } from 'virtual:pwa-register/vue'
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'

const intervalMS = 10 * 1000 // 1 hour //TODO change to 1hour again
const SW_UPDATE_FLAG = 'sw-updated'
export const useCSIServiceWorkerStore = defineStore('csiServiceWorkerStore', () => {

    // const needRefresh = ref(false)
    // const offlineReady = ref(false)
    const installing = ref(false)
    const isNewVersion = ref(localStorage.getItem(SW_UPDATE_FLAG) === 'true')
    let registration: ServiceWorkerRegistration | null = null

    console.log('🔥 Service Worker store created')
    const {
        needRefresh,
        offlineReady,
        updateServiceWorker,
    } = useRegisterSW({
        onNeedRefresh() {
            console.log('🔥 Service Worker needs refresh')
            needRefresh.value = true
        },
        onOfflineReady() {
            console.log('🔥 Service Worker is ready to work offline')
            offlineReady.value = true
        },
        onRegisteredSW(swScriptUrl, _registration) {
            console.log('🔥 Service Worker registered', swScriptUrl, _registration)
            if (_registration && _registration.waiting != null) installing.value = true
            registration = _registration ?? null
            // setInterval(checkForUpdates, intervalMS)
        },
        onRegisterError() {
            console.log('🔥 Service Worker registration failed')
            installing.value = false
        },
    })

    async function checkForUpdates() {
        if (registration == null) return console.log('🔥 Service Worker update check failed')
        console.log('🔥⏱️ Service Worker update check')
        await registration?.update()
            .catch((err) => {
                console.log('🔥 Service Worker update check failed', err)
            })
    }

    const router = useRouter()
    router.beforeEach(() => {
        checkForUpdates()
    })

    async function update() {
        console.log('🔥 Service Worker Update')
        localStorage.setItem(SW_UPDATE_FLAG, 'true')
        installing.value = true
        await updateServiceWorker()
        installing.value = false
    }

    function clearNewVersionFlag() {
        localStorage.removeItem(SW_UPDATE_FLAG)
        isNewVersion.value = false
    }

    watch(isNewVersion, () => {
        if (isNewVersion.value) {
            console.log('🔥🖼️ Service Worker Store detected, this is a fresh installed version.')
            clearNewVersionFlag()
        }
    })

    return {
        needRefresh,
        offlineReady,
        installing,
        update,
        isNewVersion,
        clearNewVersionFlag,
    }
})