<script setup lang="ts">
import CuraSoftLogo from '@/components/shared/CuraSoftLogo.vue'
import { useCSIServiceWorkerStore } from '@/store/CSIServiceWorkerStore.ts'

const swStore = useCSIServiceWorkerStore()
</script>

<template>
    <div
        class="fixed inset-0 font-display text-white"
        style="
      background: radial-gradient(at 94% 90%, #1a2849 0px, transparent 50%), radial-gradient(at 35% 24%, #505bda 0px, transparent 50%), radial-gradient(at 32% 85%, #b063c5 0px, transparent 50%), radial-gradient(at 11% 82%, #ffaac3 0px, transparent 50%), #1a2849;
    "
    >
        <div class="grid h-full place-items-center">
            <div class="flex flex-col items-center justify-center gap-4 p-4">
                <div class="flex items-center gap-4">
                    <CuraSoftLogo
                        class="inline size-16 motion-translate-y-in-50 motion-opacity-in-0 motion-duration-500/translate"
                        color="white"
                    />
                    <h1 class="translate-y-1 text-5xl font-bold text-white motion-translate-y-in-50 motion-opacity-in-0 motion-duration-500 motion-delay-100">
                        CuraSoft Intern
                    </h1>
                </div>
                <p>
                    neue Version wird installiert
                </p>
                <div class="loader"></div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.loader {
    width: 0;
    height: 4.8px;
    display: inline-block;
    position: relative;
    background: #FFF;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    box-sizing: border-box;
    animation: animFw 8s linear infinite;
}

.loader::after,
.loader::before {
    content: '';
    width: 10px;
    height: 1px;
    background: #FFF;
    position: absolute;
    top: 9px;
    right: -2px;
    opacity: 0;
    transform: rotate(-45deg) translateX(0px);
    box-sizing: border-box;
    animation: coli1 0.3s linear infinite;
}

.loader::before {
    top: -4px;
    transform: rotate(45deg);
    animation: coli2 0.3s linear infinite;
}

@keyframes animFw {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@keyframes coli1 {
    0% {
        transform: rotate(-45deg) translateX(0px);
        opacity: 0.7;
    }
    100% {
        transform: rotate(-45deg) translateX(-45px);
        opacity: 0;
    }
}

@keyframes coli2 {
    0% {
        transform: rotate(45deg) translateX(0px);
        opacity: 1;
    }
    100% {
        transform: rotate(45deg) translateX(-45px);
        opacity: 0.7;
    }
}

</style>
